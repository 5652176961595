import React from "react";
import { graphql } from "gatsby";

import ContactConnect from "../../components/owner-since/contact-connect";
import Layout from "../../components/shared/layout-light";

export const query = graphql`
  query {
    yoel: file(relativePath: { eq: "owner-since/customer-yoel-b.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function ContactConnectSpecialistPage({ data }) {
  return (
    <Layout homeUrl="/owner-since">
      <ContactConnect
        contactType="Product Specialist"
        imageData={data.yoel.childImageSharp.fluid}
        formIntro="With a few details, we’ll be able to connect you with an Outer Product Specialist"
        quote="The quality of the Outer Customer Service team is spectacular. They are always friendly, available, responsive, and knowledgeable."
        quoteSource="Yoel B. | Georgia"
      />
    </Layout>
  );
}

export default ContactConnectSpecialistPage;
